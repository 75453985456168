function isWebp() {
    // Проверка поддержки webp
    function testWebP(callback) {
        let webP = new Image();
        webP.onload = webP.onerror = function () {
            callback(webP.height == 2);
        };
        webP.src = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
    }
    // Добавление класса _webp или _no-webp для HTML
    testWebP(function (support) {
        let className = support === true ? 'webp' : 'no-webp';
        document.documentElement.classList.add(className);
    });
}
isWebp()

function menuInit() {
    let iconMenu = document.querySelector('.icon-menu')
    let menuBody = document.querySelector('.menu-mobile')
    let body = document.querySelector('body')

    iconMenu.addEventListener('click', () => {
        iconMenu.classList.toggle('_active')
        menuBody.classList.toggle('_active')
        if(menuBody.classList.contains('_active')) {
            body.style.overflow="hidden"
        }

        if(!menuBody.classList.contains('_active')) {
            body.style.overflow="auto"
        }

    })
}
menuInit()
function isTabs() {
    // Variables
    const tabs = document.querySelectorAll('.item-spoiler');

// Functions
    const toggleTab = (event) => {
        event.currentTarget.classList.toggle('active');
    };

// Event Listeners
    tabs.forEach((tab) => {
        tab.addEventListener('click', toggleTab);
    });
}
isTabs()
function isRange() {
    const slideValue = document.querySelector("#cash");
    const inputSlide = document.querySelector("#myRange");
    let sum = document.querySelector("#sum");
    let percent = document.querySelector('#percent')
    let returnInner = document.querySelector('#returnPercentSum')
    let innerDay = document.querySelector('#innerDay')

    let dayCount = document.querySelector('#dayCount')
    let dayCount2 = document.querySelector('#dayCount2')

    let dayArr = ['1', '2', '3', '4', '5', '6','7', '8', '9', '10', '11', '12','13', '14', '15', '16', '17', '18', '19','20', '21', '22', '23', '24', '25','26','27', '28','29','30', '31']

    const slideValueDay = document.querySelector("#day");
    const inputSlideDay = document.querySelector("#myRangeDay");
    const innerDate = document.querySelector('#today')


    let today = new Date();
    const resultDate = today.setDate(today.getDate() + Number(inputSlideDay.value))
    let resultToday = new Date(resultDate)
    const tyyyy = today.getFullYear();
    let tmm = today.getMonth() + 1; // Months start at 0!
    let tdd = today.getDate();
    if (tdd < 10) dd = '0' + tdd;
    if (tmm < 10) mm = '0' + tmm;
    const resultTodayDate = tdd + '.' + tmm + '.' + tyyyy;
    const yyyy = resultToday.getFullYear();
    let mm = resultToday.getMonth() + 1; // Months start at 0!
    let dd = resultToday.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = dd + '.' + mm + '.' + yyyy;
    innerDate.innerHTML = formattedToday;

    function createLabel(number, titles) {
        const cases = [2, 0, 1, 1, 1, 2];
        dayCount.innerHTML = `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
        dayCount2.innerHTML = `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
    }
    let inputValue = Number(inputSlide.value);
    let valueDay = Number(inputSlideDay.value);

    createLabel(valueDay, ['День', 'Дня', 'Днів'])
    // console.log(valueDay)

    function updеteData() {
        inputValue = Number(inputSlide.value);
        change(inputValue)

        valueDay = Number(inputSlideDay.value);
        changeDay(valueDay);
        // console.log(valueDay)

        createLabel(valueDay, ['День', 'Дня', 'Днів'])
    }

    inputSlideDay.oninput = (() =>{
        updеteData()
    });
    changeDay(valueDay);
    function changeDay(valueDay) {
        let resultDay = ((inputSlideDay.value - inputSlideDay.min) * 100) / (inputSlideDay.max - inputSlideDay.min)
        slideValueDay.innerHTML = valueDay;


        inputSlideDay.style.background = `linear-gradient(to right, #57B8FF 0%, #57B8FF ${resultDay}%, #E3E3E3 ${resultDay}%, #E3E3E3 100%)`
        innerDay.innerHTML = valueDay

        let today = new Date();
        const resultDate = today.setDate(today.getDate() + Number(inputSlideDay.value))
        let resultToday = new Date(resultDate)
        const yyyy = resultToday.getFullYear();
        let mm = resultToday.getMonth() + 1; // Months start at 0!
        let dd = resultToday.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = dd + '.' + mm + '.' + yyyy;
        innerDate.innerHTML = formattedToday;
        let valPercent = Number(inputValue * 0.0001);
        returnInner.innerHTML = (inputValue + (valPercent * Number(inputSlideDay.value))).toFixed(2)
    }

    inputSlide.oninput = (() =>{

        updеteData()

    });
    change(inputValue)
    function change(inputSlideValue) {
        let result =  ((inputSlideValue - inputSlide.min) * 100) / (inputSlide.max - inputSlide.min)

        let valPercent =( Number(inputSlideValue * 0.0001)) * Number(inputSlideDay.value) ;
        console.log(inputSlideDay.value)
        console.log(valPercent)
        percent.innerText = valPercent.toFixed(2);

        inputSlide.style.background = `linear-gradient(to right, #57B8FF 0%, #57B8FF ${result}%, #E3E3E3 ${result}%, #E3E3E3 100%)`
        slideValue.innerText =  inputValue
        let sumInner = inputValue
        sum.innerText = sumInner

        // let returnSum = Math.round(sumInner * (100 + percentNumb) / 100)
        let returnSum = Math.round(inputSlideValue + valPercent * Number(inputSlideDay.value)).toFixed(2)
        returnInner.innerHTML = (inputValue + (valPercent * Number(inputSlideDay.value))).toFixed(2)
    }



}
isRange()

$("a").on('click', function(e) {
    e.preventDefault();
    let  urlParams = window.location.href.split('?')[1]
    let  hrefParams =  $(this).attr('href').split('?')[1]
    if (hrefParams && urlParams) {
        return  window.location.href = $(this).attr('href').split('?')[0]+'?'+hrefParams+'&'+urlParams
    } else if (hrefParams) {
        return  window.location.href = $(this).attr('href').split('?')[0]+'?'+hrefParams
    } else if (urlParams) {
        return  window.location.href = $(this).attr('href').split('?')[0]+'?'+urlParams

    }
    window.location.href = $(this).attr('href');
});

